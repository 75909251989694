<template>
  <div class="container">
    <div class="box top">
      <div class="report-item">
        <div class="tag-icon">
          <el-image :src="reportItem.xiaoshoue.icon" alt=""></el-image>
        </div>
        <div class="item-content">
          <p class="bold-number">{{ reportData.handlePrice || 0.0 }}</p>
          <p class="des">今日实际销售额</p>
        </div>
      </div>
      <div class="report-item">
        <div class="tag-icon">
          <el-image :src="reportItem.saleNum.icon" alt=""></el-image>
        </div>
        <div class="item-content">
          <p>
            <span>销售</span>
            <span class="bold-number">{{ reportData.orderNumber || 0 }}</span>
            <span>退货</span>
            <span class="bold-number">{{
              reportData.orderRefundNumber || 0
            }}</span>
          </p>
          <p class="des">今日销售单据数量</p>
        </div>
      </div>
      <div class="report-item">
        <div class="tag-icon">
          <el-image :src="reportItem.huikuan.icon" alt=""></el-image>
        </div>
        <div class="item-content">
          <p class="bold-number">{{ reportData.prepaidPrice || 0.0 }}</p>
          <p class="des">今日销售回款</p>
        </div>
      </div>
      <div class="report-item">
        <div class="tag-icon">
          <el-image :src="reportItem.qiankuan.icon" alt=""></el-image>
        </div>
        <div class="item-content">
          <p class="des">今日新增客户欠款</p>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="normal box">
        <p class="title">常用功能</p>
        <ul class="content FSB">
          <li
            class="item"
            v-for="(item, index) in menuList"
            :key="index"
            @click="itemClick(item.path)"
          >
            <div class="item-icon">
              <el-image :src="item.pic" alt=""></el-image>
            </div>
            <div class="item-text">{{ item.name }}</div>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="contact_us">
          <div class="contact_us_head">
            <div>联系我们</div>
          </div>
          <div style="font-size: 18px; margin-top: 13px">
            客服热线（9:00～18:00）
          </div>
          <div style="font-size: 30px; color: #3a92fb; margin-top: 20px">
            400-966-7622
          </div>
          <div style="font-size: 18px; margin-top: 23px">
            一对一人工客服（9:00～18:00）
          </div>
          <div class="contact_us_footer">
            <div>
              <img
                src="~@/assets/image/kefuQrcode.png"
                alt=""
                srcset=""
                width="107"
                height="104"
              />
            </div>
            <div style="position: absolute; right: 10px; top: 0px">
              <img
                src="~@/assets/image/kefu.png"
                alt=""
                srcset=""
                width="119"
                height="100"
              />
            </div>
          </div>
        </div>

        <div class="download">
          <div class="download-content">
            <p class="download-title">多端下载</p>
            <div>
              <div class="android-btn">
                <img src="~@/assets/image/android.png" alt="" srcset="" />安卓版
              </div>
              <div class="ios-btn">
                <img src="~@/assets/image/ios.png" alt="" srcset="" />苹果端
              </div>
              <!-- <el-button class="download-btn" type="primary" plain round>安卓端</el-button>
            <el-button class="download-btn" type="primary" plain round>苹果端</el-button> -->
            </div>
          </div>
          <div class="download-qrcode">
            <!-- 二维码 -->
            <img
              src="~@/assets/image/qrcode.jpg"
              alt=""
              srcset=""
              width="100%"
            />
          </div>
        </div>
        <div class="tuijian">
          <div class="tuijian-content">
            <p class="tuijian-title">推荐有奖</p>
            <p>推荐好友使用并付费后可<span>可获得现金奖励直接微信提现</span></p>
            <el-button class="yaoqing-btn" type="primary" round
              >邀请好友</el-button
            >
          </div>
        </div>
        <!-- <div class="box update-record">
          <div class="update-title">更新日志</div>
          <div></div>
        </div> -->
      </div>
    </div>

    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>

<script>
import { getConfigBill } from "@/api/user";
import { getTodayReport } from "@/api/common";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    OpenAccount,
  },
  data() {
    return {
      info: {
        show: false,
      },
      reportData: {},
      reportItem: {
        xiaoshoue: {
          icon: require("../../assets/image/tag-green.png"),
        },
        saleNum: {
          icon: require("../../assets/image/tag-red.png"),
        },
        huikuan: {
          icon: require("../../assets/image/tag-yellow.png"),
        },
        qiankuan: {
          icon: require("../../assets/image/tag-blue.png"),
        },
      },
      menuList: [
        {
          pic: require("../../assets/image/purchase.png"),
          name: "采购记录",
          path: "/purchase/putstorage",
        },
        // {
        //   pic: require("../../assets/image/sale.png"),
        //   name: "销售开单",
        //   path: "/sale/retailOutletList/addSaleOut",
        // },
        {
          pic: require("../../assets/image/saleOutOrder.png"),
          name: "销售记录",
          path: "/sale/retailOutletList/saleOutList",
        },
        {
          pic: require("../../assets/image/kczkb.png"),
          name: "库存状况表",
          path: "/inventoryTable/inventoryStatusTable/index",
        },
        {
          pic: require("../../assets/image/taizhang_home.png"),
          name: "台账管理",
          path: "/purchase-ledger/index",
        },
        // {
        //   pic: require("../../assets/image/saleReturnOrder.png"),
        //   name: "采购记录",
        //   path: "/inventoryTable/inventoryStatusTable/index",
        // },
        {
          pic: require("../../assets/image/payee.png"),
          name: "新增供应商",
          path: "/data/supplierData",
        },
        {
          pic: require("../../assets/image/customerInfo.png"),
          name: "新增客户",
          path: "/data/customer",
        },
        {
          pic: require("../../assets/image/productInfo.png"),
          name: "新增商品",
          path: "/commodity/message",
        },
        // {
        //   pic: require("../../assets/image/pay.png"),
        //   name: "新增收款单",
        //   path: "/finance/financeReport/contactPayTabel",
        // },
        {
          pic: require("../../assets/image/settings.png"),
          name: "系统设置",
          path: "/system/parameter",
        },
      ],
    };
  },
  created() {
    // this.getConfigBills();
    this.getReport();
  },
  methods: {
    itemClick(path) {
      this.$router.push({
        path,
      });
    },
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
        }
      }
    },
    getReport() {
      getTodayReport().then((res) => {
        if (res.code === 1) {
          this.reportData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.android-btn {
  display: inline-block;
  width: 98px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #4f9cf3;
  border-radius: 15px;
  text-align: center;
  position: relative;
  padding-left: 10px;
  img {
    position: absolute;
    top: 6px;
    left: 15px;
  }
}
.ios-btn {
  display: inline-block;
  width: 98px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #4f9cf3;
  border-radius: 15px;
  position: relative;
  text-align: center;
  padding-left: 10px;
  margin-left: 20px;
  img {
    position: absolute;
    top: 6px;
    left: 15px;
  }
}
.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box {
  background: #ffffff;
  box-shadow: 0px 5px 12px 1px rgba(230, 234, 245, 0.8);
  border-radius: 30px;
  margin: 20px;
}
.top {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 210px;
  .report-item {
    position: relative;
    width: 408px;
    height: 150px;
    background: #f8f9fd;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;
    .tag-icon {
      position: absolute;
      left: 30px;
      top: 0;
      .el-image {
        width: 36px;
        height: 40px;
      }
    }
    .item-content {
      font-size: 24px;
      color: #666;
      .bold-number {
        font-size: 33px;
        font-weight: 600;
        color: #333;
      }
      .des {
        font-size: 20px;
        color: #666;
        letter-spacing: 2px;
      }
    }
  }
}

.newHand {
  margin-right: 150px;
}
.text {
  color: #666;
  margin-left: 5px;
  cursor: pointer;
}
.normal {
  width: 1314px;
  padding-bottom: 50px;
  .title {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin: 30px;
  }
  .content {
    width: 1104px;
    margin: 0 auto;
    flex-wrap: wrap;

    .item {
      width: 234px;
      height: 230px;
      list-style: none;
      background: #fffffe;
      border: 1px solid #e8e8e8;
      opacity: 0.9;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 30px;
      text-align: center;
      .item-icon {
        width: 84px;
        height: 70px;
        margin: 45px auto 38px;
      }
      .item-text {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
.right {
  width: 438px;
  margin-top: 19px;
  .contact_us {
    width: 400px;
    height: 322px;
    background: #f6fafd;
    box-shadow: 0px 5px 12px 1px rgba(230, 234, 245, 0.8);
    border-radius: 30px;
    padding: 20px;
    font-size: 26px;
    color: #333;
    .contact_us_head {
      height: 42px;
      border-bottom: 1px solid #e8e8e8;
    }
    .contact_us_footer {
      position: relative;
      width: 100%;
      margin-top: 20px;
    }
  }
  .tuijian {
    width: 100%;
    height: 164px;
    background-image: url("~@/assets/image/tuijian.png");
    background-size: 100% 100%;
    .tuijian-content {
      width: 288px;
      padding: 20px;
      font-size: 16px;
      color: #666;
      .tuijian-title {
        font-size: 26px;
        color: #333;
        margin-bottom: 10px;
      }
      .yaoqing-btn {
        margin-top: 10px;
        line-height: 5px;
      }
    }
  }
  .download {
    width: 438px;
    height: 114px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ebf5ff;
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    .download-content {
      width: 70%;
      .download-title {
        font-size: 26px;
        color: #333;
        margin-bottom: 10px;
      }
      .download-btn {
        line-height: 5px;
      }
    }
    .download-qrcode {
      width: 20%;
      position: absolute;
      right: 20px;
      top: 13px;
    }
  }
  .update-record {
    width: 438px;
    height: 356px;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
    .update-title {
      font-size: 26px;
      color: #333;
      padding-bottom: 10px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
</style>
